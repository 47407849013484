<template>
  <div>
    <div id="contactus" class="contactUs">
      <div class="container_contact" id="container_contact">
        <!-- <strong
          >Transform Neurological and Educational Care with NeuroLeap</strong
        >
        <br />
        Our end-to-end AI solutions provide healthcare providers, schools, and
        rehabilitation centers with the tools they need to deliver precision
        care and personalized interventions/specialized lessons. Whether you’re
        a healthcare provider, school, or clinic, NeuroLeap’s integrated
        hardware and software platform can significantly help improve outcomes
        for both patients and students.
        <br />
        <br />

        <strong>What We Offer:</strong>
        <ul>
          <li>
            Integrated AI hardware and software solutions for real-time
            neurological and educational care.
          </li>
          <li>Customizable platforms for hospitals, clinics, and schools.</li>
          <li>
            Third-party marketplace for your
            norm-referenced/criterion-referenced screening, assessment and
            intervention method(s)/tool(s) to shine brighter and generate more
            revenue for you.
          </li>
        </ul> -->

        <div class="container_contact_wrapper">
          <!-- content left -->

          <div class="container_left">
            <h1>{{ $t("contact.company") }}</h1>
            <p>
              {{ $t("contact.address1") }}
              <br />
              {{ $t("contact.address2") }}
            </p>
            <a
              href="https://www.facebook.com/pages/category/Education/Neuro-Leap-Corp-278266009350133/"
              target="_blank"
              class="media_name"
              ><img
                src="../img/facebook.png"
                class="icon_socialmedia"
                alt="facebook"
            /></a>
            <a
              href="https://www.linkedin.com/company/neuroleap-corp?trk=similar-companies_org_title"
              target="_blank"
              class="media_name"
              ><img
                src="../img/linkedin.png"
                class="icon_socialmedia"
                alt="linkedin"
            /></a>
            <a
              href="https://twitter.com/LeapNeuro"
              target="_blank"
              class="media_name"
              ><img
                src="../img/NLC new website/x.png"
                class="icon_socialmedia"
                alt="twitter"
            /></a>
            <a
              href="https://www.threads.net/"
              target="_blank"
              class="media_name"
              ><img
                src="../img/NLC new website/threads.png"
                class="icon_socialmedia"
                alt="threads"
            /></a>
          </div>
        </div>
        <br />

        <!-- content mid: contact form -->
        <div class="container_mid">
          <table id="hiddenContact">
            <tr>
              <td>
                <!-- contact form button -->
                <div>
                  <h3>Connect with us</h3>
                </div>
                <br />

                <span
                  >Are you someone who works with children with disabilities?
                  We’d love for you to try Neuroleap!</span
                >
                <br />
                <el-button
                  id="contactbtn"
                  v-on:click="
                    showContactform();
                    clearOtherForm('contact');
                  "
                  round
                  >{{ $t("contact.form") }} below</el-button
                >
              </td>
            </tr>
            <tr>
              <td>
                <!-- field trial button -->
                <div>
                  <h3>Join our field trials</h3>
                </div>
                <br />
                <span
                  >Do you have an individual that you’d like to enroll in our
                  field trial? Learn more here!</span
                >
                <br />
                <el-button id="trialbtn" round>
                  <router-link to="/fieldTrial" style="color: #dc6803"
                    >Field Trials</router-link
                  >
                </el-button>
              </td>
            </tr>
            <tr>
              <td>
                <!-- newsletter button -->
                <div>
                  <h3>Our Newsletter</h3>
                </div>
                <br />
                <span>Stay updated on the progress we are making. </span>
                <br />
                <el-button
                  disabled
                  id="newsbtn"
                  v-on:click="
                    showNewsletter();
                    clearOtherForm('news');
                  "
                  round
                  >{{ $t("newsletter.heading") }}</el-button
                >
              </td>
            </tr>
          </table>
          <table id="shownContact">
            <tr>
              <td>
                <!-- contact form button -->
                <div>
                  <h2>Connect with us</h2>
                </div>
              </td>

              <td class="gap"></td>

              <td>
                <!-- field trial button -->
                <div>
                  <h2>Join our field trials</h2>
                </div>
              </td>

              <td class="gap"></td>

              <td>
                <!-- newsletter button -->
                <div>
                  <h2>Our Newsletter</h2>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  >Are you someone who works with children with disabilities?
                  We’d love for you to try Neuroleap!</span
                >
              </td>
              <td class="gap"></td>

              <td>
                <span
                  >Do you have an individual that you’d like to enroll in our
                  field trial? Learn more here!</span
                >
              </td>
              <td class="gap"></td>

              <td>
                <span>Stay updated on the progress we are making. </span>
              </td>
            </tr>
            <tr>
              <td>
                <el-button
                  id="contactbtn"
                  v-on:click="
                    showContactform();
                    clearOtherForm('contact');
                  "
                  round
                  >{{ $t("contact.form") }}</el-button
                >
              </td>
              <td class="gap"></td>

              <td>
                <el-button id="trialbtn" round>
                  <router-link to="/fieldTrial" style="color: #dc6803"
                    >Field Trials</router-link
                  >
                </el-button>
              </td>
              <td class="gap"></td>

              <td>
                <el-button
                  disabled
                  id="newsbtn"
                  v-on:click="
                    showNewsletter();
                    clearOtherForm('news');
                  "
                  round
                  >{{ $t("newsletter.heading") }}</el-button
                >
              </td>
            </tr>
          </table>

          <br />

          <!-- start newsletter -->
          <div>
            <div id="newsletter" class="newsletter" style="display: none">
              <h1>{{ $t("newsletter.heading") }}</h1>

              <div class="container_newsletter">
                <!-- content mid: contact form -->
                <div class="container_mid">
                  <div id="newsletterform">
                    <h1 class="brand">
                      <span>{{ $t("newsletterform.neuro") }}</span>
                      {{ $t("newsletterform.leap") }}
                    </h1>
                    <div class="newsletter_wrapper">
                      <div class="newsletterform">
                        <div class="alert">
                          {{ $t("newsletterform.msg_success") }}
                        </div>
                        <form
                          id="newsletterform"
                          ref="formNews"
                          @submit.prevent="sendEmail('newsletter')"
                        >
                          <!-- firstname -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_firstname") }} *</label
                            >
                            <input
                              type="required"
                              name="firstname"
                              id="firstname2"
                            />
                            <span id="s_firstname2" class="warning"></span>
                          </p>
                          <!-- lastname -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_lastname") }} *</label
                            >
                            <input
                              type="required"
                              name="lastname"
                              id="lastname2"
                            />
                            <span id="s_lastname2" class="warning"></span>
                          </p>
                          <!-- city -->
                          <p>
                            <label>{{ $t("newsletterform.lbl_city") }} *</label>
                            <input type="text" name="city" id="city2" />
                            <span id="s_city2" class="warning"></span>
                          </p>

                          <!-- state -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_state") }} *</label
                            >
                            <input type="text" name="state" id="state2" />
                            <span id="s_state2" class="warning"></span>
                          </p>
                          <!-- country -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_country") }} *</label
                            >
                            <input type="text" name="country" id="country2" />
                            <span id="s_country2" class="warning"></span>
                          </p>
                          <!-- zipcode -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_zipcode") }} *</label
                            >
                            <input type="text" name="zipcode" id="zip2" />
                            <span id="s_zip2" class="warning"></span>
                          </p>
                          <!-- email -->
                          <p>
                            <label
                              >{{ $t("newsletterform.lbl_email") }} *</label
                            >
                            <input type="required" name="email" id="email2" />
                            <span id="s_email2" class="warning"></span>
                          </p>
                          <!-- role -->
                          <p class="full">
                            <label>{{ $t("newsletterform.lbl_role") }} *</label>
                            <select name="interestgroup" id="interestgroup">
                              <option>CEO</option>
                              <option>CTO</option>
                              <option>CFO</option>
                              <option>
                                {{ $t("newsletterform.role_law") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_specialist") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_healthcare") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_representive") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_teacher") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_parent") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_PCP") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_press") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_investor") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_volunteer") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_support") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.role_others") }}
                              </option>
                            </select>
                          </p>

                          <!-- frequency -->
                          <p class="full">
                            <label
                              >{{ $t("newsletterform.frequency") }} *</label
                            >
                            <select name="frequency" id="frequency">
                              <option>
                                {{ $t("newsletterform.one_month") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.three_months") }}
                              </option>
                              <option>
                                {{ $t("newsletterform.one_year") }}
                              </option>
                            </select>
                          </p>
                          <input
                            type="hidden"
                            name="thankyou_url"
                            value="http://localhost:8080/"
                          />
                          <input
                            type="hidden"
                            name="subject"
                            value="newsletterform Request"
                          />
                          <input
                            type="hidden"
                            name="my_email"
                            value="shravani@neuroleap.com"
                          />
                          <input
                            type="hidden"
                            name="required"
                            value="firstname,lastname,email,frequency"
                          />
                          <input
                            type="hidden"
                            name="order"
                            value="firstname,lastname,city,email,interestgroup,frequency"
                          />
                          <!-- submit -->
                          <p>
                            <b-button
                              id="newsSubmit"
                              type="submit"
                              v-b-modal.modal-1
                              class="btn_newsletterform"
                              @click="handleNewsletterForm()"
                            >
                              {{ $t("newsletterform.btn_submit") }}
                            </b-button>
                          </p>
                          <p>
                            <b-button
                              round
                              type="reset"
                              class="btn_newsletterform"
                            >
                              {{ $t("newsletterform.btn_reset") }}
                            </b-button>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                  <!-- end newsletterform -->
                </div>
              </div>
            </div>
          </div>
          <div>
            <b-modal id="modal-1" v-model="showThankYouModal">
              <h2>Thank you for your submission!</h2>
              <br />
              <p>We will get back to you soon.</p>
            </b-modal>
          </div>
          <!-- end newsletter -->
        </div>
        <!-- start contactform -->
        <div id="contactform" style="display: none; width: 100%">
          <!-- title -->
          <!-- <h1 class="brand">
            {{ $t("contactform.neuro") }}
            {{ $t("contactform.leap") }}
          </h1> -->
          <div class="contact_wrapper" style="width: 100%">
            <div class="contactform" style="width: 100%">
              <h3>Contact Form</h3>
              <br />
              <!-- not working right now -->
              <div class="alert">{{ $t("contactform.msg_success") }}</div>
              <form
                style="width: 100%"
                ref="formContact"
                @submit.prevent="sendEmail('contact')"
                id="contactForm"
                method="post"
                action="https://www1.ipage.com/scripts/formemail.html"
              >
                <table style="width: 100%">
                  <tr>
                    <td>
                      <!-- firstname -->

                      <label>{{ $t("contactform.lbl_firstname") }} *</label>
                    </td>

                    <td>
                      <!-- lastname -->

                      <label>{{ $t("contactform.lbl_lastname") }} *</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="required"
                        name="firstname"
                        id="firstname1"
                        required
                      />
                      <span id="s_firstname1" class="warning"></span>
                    </td>
                    <td>
                      <input
                        type="required"
                        name="lastname"
                        id="lastname1"
                        required
                      />
                      <span id="s_lastname1" class="warning"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <!-- city -->
                      <label>{{ $t("contactform.lbl_city") }} *</label>
                    </td>

                    <td>
                      <!-- State / Prefecture / Province -->
                      <label>{{ $t("contactform.lbl_state") }} *</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" name="city" id="city1" required />
                      <span id="s_city1" class="warning"></span>
                    </td>
                    <td>
                      <input type="text" name="state" id="state1" required />
                      <span id="s_state1" class="warning"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <!-- Country -->
                      <label>{{ $t("contactform.lbl_country") }} *</label>
                    </td>

                    <td>
                      <!-- Zipcode / Postal code -->
                      <label>{{ $t("contactform.lbl_zipcode") }}*</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="country"
                        id="country1"
                        required
                      />
                      <span id="s_country1" class="warning"></span>
                    </td>
                    <td>
                      <input type="text" name="zipcode" id="zip1" required />
                      <span id="s_zip1" class="warning"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <!-- email -->
                      <label>{{ $t("contactform.lbl_email") }} *</label>
                    </td>

                    <td>
                      <!-- phone number -->
                      <label>{{ $t("contactform.lbl_phone") }} *</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="required"
                        name="email"
                        id="email1"
                        required
                      />
                      <span id="s_email1" class="warning"></span>
                    </td>
                    <td>
                      <input type="text" name="phone" id="phone1" required />
                      <span id="s_phone1" class="warning"></span>
                    </td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <label>{{ $t("contactform.lbl_role") }}*</label>
                  </tr>
                  <tr>
                    <select
                      required
                      v-model="role"
                      name="interestgroup"
                      id="interestgroup"
                      style="width: 50%"
                    >
                      <option
                        value="Parent / Close relative / Care-taker to a young child"
                      >
                        {{ $t("contactform.role_parent") }}
                      </option>
                      <option value="Potential investor">
                        {{ $t("contactform.role_investor") }}
                      </option>
                      <option value="Potential volunteer">
                        {{ $t("contactform.role_volunteer") }}
                      </option>
                      <option value="Potential supporter / collaborator">
                        {{ $t("contactform.role_supporter") }}
                      </option>
                      <option value="Others">
                        {{ $t("contactform.role_others") }}
                      </option>
                    </select>
                  </tr>
                  <tr>
                    <label for="jobFunction">Job Function</label>
                  </tr>
                  <tr>
                    <select
                      name="jobFunction"
                      v-model="jobFunction"
                      style="width: 50%"
                    >
                      <option value="Accounting">Accounting</option>
                      <option value="Admin & HR, Banking / Finance">
                        Admin & HR, Banking / Finance
                      </option>
                      <option value="Beauty Care / Health">
                        Beauty Care / Health
                      </option>
                      <option value="Building & Construction">
                        Building & Construction
                      </option>
                      <option value="Design">Design</option>
                      <option value="E-Commerce">E-Commerce</option>
                      <option value="Education">Education</option>
                      <option value="Hospitality / F&B">
                        Hospitality / F&B
                      </option>
                      <option value="Information Technology">
                        Information Technology
                      </option>
                      <option value="Insurance">Insurance</option>
                      <option value="Management">Management</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Marketing / Public Relations">
                        Marketing / Public Relations
                      </option>
                      <option value="Media / Advertising">
                        Media / Advertising
                      </option>
                      <option value="Medical Servcie">Medical Servcie</option>
                      <option value="Merchandising & Purchasing">
                        Merchandising & Purchasing
                      </option>
                      <option value="Professional Service">
                        Professional Service
                      </option>
                      <option value="Property / Rental Estate">
                        Property / Rental Estate
                      </option>
                      <option value="Public Sector / Civil Service">
                        Public Sector / Civil Service
                      </option>
                      <option value="Sales, CS & Business Development">
                        Sales, CS & Business Development
                      </option>
                      <option value="Science / R&D">Science / R&D</option>
                      <option value="Transportation & Logistics">
                        Transportation & Logistics
                      </option>
                      <option value="A self-employed Specialist">
                        A self-employed Specialist
                      </option>
                      <option value="A Specialist working for an organization">
                        A Specialist working for an organization
                      </option>
                      <option value="Parent/Care-taker of a Child">
                        Parent/Care-taker of a Child
                      </option>
                      <option value="Primary Care Physician">
                        Primary Care Physician
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </tr>
                  <tr>
                    Job Title
                  </tr>
                  <tr>
                    <input
                      type="text"
                      name="jobTitle"
                      v-model="jobTitle"
                      style="width: 50%"
                    />
                  </tr>
                  <tr>
                    Job Level*
                  </tr>
                  <tr>
                    <select
                      name="jobLevel"
                      required
                      v-model="jobLevel"
                      style="width: 50%"
                    >
                      <option value="Executive or senior management(C-suite)">
                        Executive or senior management(C-suite)
                      </option>
                      <option
                        value="Middle management(director, general manager)"
                      >
                        Middle management(director, general manager)
                      </option>
                      <option
                        value="First-level management(assistant manager, manager, senior manager)"
                      >
                        First-level management(assistant manager, manager,
                        senior manager)
                      </option>
                      <option value="Intermediate or experienced(senior staff)">
                        Intermediate or experienced(senior staff)
                      </option>
                      <option value="Entry-level">Entry-level</option>
                      <option value="Private Party">Private Party</option>
                    </select>
                  </tr>
                  <tr>
                    <label>{{ $t("contactform.lbl_message") }}*</label>
                  </tr>
                  <tr>
                    <textarea
                      name="message"
                      rows="5"
                      id="message"
                      required
                      style="width: 100%"
                    ></textarea>
                  </tr>
                </table>

                <!-- hidden -->
                <input
                  type="hidden"
                  name="thankyou_url"
                  value="https://www.leap333.com"
                />
                <input
                  type="hidden"
                  name="subject"
                  value="Contactform Request"
                />
                <input
                  type="hidden"
                  name="my_email"
                  value="info@neuroleap.com"
                />
                <input
                  type="hidden"
                  name="required"
                  value="firstname,lastname,email,message"
                />
                <input
                  type="hidden"
                  name="order"
                  value="firstname,lastname,city,phone,email,message,interestgroup"
                />

                <!-- submit and reset button -->
                <table>
                  <tr>
                    <td>
                      <b-button
                        type="submit"
                        class="btn_contactform"
                        name="submit"
                        value="Submit Now"
                        @click="handleContactForm()"
                      >
                        {{ $t("contactform.btn_submit") }}
                      </b-button>
                    </td>
                    <td>
                      <b-button type="reset" class="btn_contactform">
                        <span style="color: black">{{
                          $t("contactform.btn_reset")
                        }}</span>
                      </b-button>
                    </td>
                  </tr>
                </table>
              </form>
            </div>
          </div>
        </div>
        <!-- end contactform -->
      </div>
    </div>
    <!-- <form ref="form" @submit.prevent="sendEmail">
      <label>Name</label>
      <input type="text" name="from_name">
      <label>Email</label>
      <input type="email" name="from_email">
      <label>Message</label>
      <textarea name="message"></textarea>
      <input type="submit" value="Send">
    </form> -->
  </div>
</template>

<script>
import axios from "../axios";
import emailJS from "../services/emailJS";
export default {
  name: "thankYouPage",
  data() {
    return {
      showThankYouModal: false,
      selectedAge: null,
      role: "",
      jobFunction: "",
      jobLevel: "",
      jobTitle: "",
      ageOptions: [
        { value: null, text: "Please select an age" },
        { value: "a", text: "7" },
        { value: "b", text: "6" },
        { value: "c", text: "7" },
        { value: "d", text: "8" },
        { value: "d", text: "9" },
        { value: "e", text: "10" },
        { value: "f", text: "11" },
        { value: "g", text: "12" },
      ],

      selectedGender: null,
      genderOptions: [
        { value: null, text: "Please select a gender" },
        { value: "a", text: "Male" },
        { value: "b", text: "Female" },
        { value: "c", text: "Gender Variant/Non-binary" },
      ],
    };
  },
  methods: {
    // only show one form
    clearOtherForm: function (curr) {
      var contact = document.getElementById("contactform");
      var news = document.getElementById("newsletter");
      if (curr == "contact") {
        if (news.style.display == "block") {
          news.style.display = "none";
        }
      } else {
        if (contact.style.display == "block") {
          contact.style.display = "none";
        }
      }
    },
    // show contact form
    showContactform: function () {
      var x = document.getElementById("contactform");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
      this.$nextTick(() => {
        x.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    },
    // show newsletter
    showNewsletter: function () {
      var x = document.getElementById("newsletter");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    sendEmail(from) {
      this.showThankYouModal = true;
      if (from == "contact") {
        emailJS.sendEmail(from, this.$refs.formContact);
      } else if (from == "newsletter") {
        emailJS.sendEmail(from, this.$refs.formNews);
      }
    },
    handleContactForm: function () {
      const formToSubmit = {
        firstname: document.getElementById("firstname1").value,
        lastname: document.getElementById("lastname1").value,
        city: document.getElementById("city1").value,
        state: document.getElementById("state1").value,
        country: document.getElementById("country1").value,
        zip: document.getElementById("zip1").value,
        email: document.getElementById("email1").value,
        phone: document.getElementById("phone1").value,
        role: this.role,
        jobFunction: this.jobFunction,
        jobLevel: this.jobLevel,
        jobTitle: this.jobTitle,
      };

      axios
        .post("api/contactus", formToSubmit)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleNewsletterForm: function (event) {
      var c1 = this.checkEmpty("firstname2");
      var c2 = this.checkEmpty("lastname2");
      var c3 = this.checkEmpty("city2");
      var c4 = this.checkEmpty("state2");
      var c5 = this.checkEmpty("country2");
      var c6 = this.checkEmpty("zip2");
      var c7 = this.checkEmpty("email2");
      if (c1 && c2 && c3 && c4 && c5 && c6 && c7) {
        console.log("GOOD");
      } else {
        event.preventDefault();
        console.log("NOT GOOD");
      }
      this.submitNewsletter();
    },

    // check text input valid
    checkEmpty: function (name) {
      var val = document.getElementById(name).value;
      var reg = /^$/;
      var flag = !reg.test(val);
      var s_val = document.getElementById("s_" + name);
      if (flag) {
        s_val.innerHTML = "";
      } else {
        s_val.innerHTML = "Warning: Invalid";
      }
      return flag;
    },

    // when submit newsletter form
    submitNewsletter() {
      const formToSubmit = {
        firstname: document.getElementById("firstname2").value,
        lastname: document.getElementById("lastname2").value,
        city: document.getElementById("city2").value,
        state: document.getElementById("state2").value,
        country: document.getElementById("country2").value,
        zip: document.getElementById("zip2").value,
        email: document.getElementById("email2").value,
      };
      axios
        .post("api/newsletter", formToSubmit)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
#contactus:target {
  padding-top: 7vh;
}

.field_trial_header {
  background-color: #f4892f;
  color: white;
  padding-left: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
}

#shownContact {
  display: block;
  border-collapse: collapse;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.container_contact {
  width: 100%;
  background-color: white;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.field_trial_wrapper {
  width: 100%;
  border: 1px solid #dcdfe6;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.field_trial_body {
  width: 100%;
  border: 1px solid #dcdfe6;
}

.container_contact_wrapper {
  width: 100%;
  display: flex;
}

.container_left {
  width: 50%;
}

.container_right {
  width: 50%;
}

.container_mid {
  width: 100%;
}

.icon_socialmedia {
  height: 18px;
  padding-right: 5px;
}

h2 {
  margin: 0;
}

a {
  color: black;
}

.el-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

#contactbtn {
  background-color: #dc6803;
  color: white;
}

#trialbtn {
  background-color: #fff9ef;
}

#newsbtn {
  border-color: #f4892f;
  color: black;
}

.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* CONTACTFORM */

* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.contact_wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
  display: flex;
}

.contact_wrapper > * {
  padding: 1em;
}
#contactform {
  background: #f9c75c;
  display: flex;
  justify-content: center;
}
.contactform table {
  width: 100%;
}

.contactform input {
  border: 2px solid #f9c75c;
  width: 100%;
}
.contactform select {
  border: 2px solid #f9c75c;
}
.contactform textarea {
  border: 2px solid #f9c75c;
}
#contactForm td {
  width: 50%;
}
.btn_contactform {
  width: 100%;
  background-color: #f4892f;
}
/* FORM STYLES */
.container_mid form {
  display: grid;
  grid-gap: 10px;
}

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form .full {
  grid-column: 1 / 3;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 1em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

.field_trial_body form {
  display: grid;
  grid-gap: 10px;
}

.field_trial_body label {
  display: block;
}

.field_trial_body p {
  margin: 0;
}

.field_trial_body .full {
  grid-column: 1 / 3;
}

.field_trial_body form button,
.field_trial_body form input,
.field_trial_body textarea,
.field_trial_body form select,
.field_trial_body form option {
  width: 100%;
  padding: 1em;
  border: 1px solid #f2f2f2;
  background-color: #f2f2f2;
}

.field_trial_body form button {
  background: #f2f2f2;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.field_trial_body form button:hover,
.field_trial_body form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

/* @media screen and (min-width: 1380px) and (max-width: 1680px) {
  .contactUs {
    top: -450px;
    margin-bottom: -450px;
  }
}
@media screen and (max-width: 1380px) {
  .contactUs {
    top: -400px;
    margin-bottom: -400px;
  }
}
@media screen and (max-width: 991px) {
  .contactUs {
    top: -330px;
    margin-bottom: -330px;
  }
}
@media screen and (max-width: 700px) {
  .contactUs {
    top: -250px;
    margin-bottom: -250px;
  }
}
@media screen and (max-width: 1397px) and (min-width: 860px) {
  #contactus:target {
    padding-top: 15vh;
  }
} */

/* LARGE SCREENS */
/* @media (min-width: 700px) {
  .contact_wrapper {
    display: grid;
  }

  .contact_wrapper > * {
    padding: 2em;
  }

  .field_trial_wrapper {
    display: grid;
  }

  .field_trial_wrapper > * {
    padding: 2em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
} */

/* @media screen and (max-width: 601px) {
  .container_contact_wrapper {
    display: block;
  }

  .field_trial_wrapper {
    display: block;
  }
} */

.container_newsletter {
  width: 100%;
  background-color: #f4892f;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.container_mid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  margin: 0;
}

a {
  color: black;
}

.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* newsletterform */
* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.newsletter_wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
}

.newsletter_wrapper > * {
  padding: 1em;
}

.newsletterform {
  background: #f9c75c;
}

/* FORM STYLES */
.container_mid form {
  display: grid;
  grid-gap: 10px;
}

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form .full {
  grid-column: 1 / 3;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 1em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

/* LARGE SCREENS */
/* @media (min-width: 700px) {
  .newsletter_wrapper {
    display: grid;
  }

  .newsletter_wrapper > * {
    padding: 2em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
} */

/* @media screen and (max-width: 601px) {
  .container_newsletter_wrapper {
    display: block;
  }
} */

.warning {
  color: red;
  font-weight: bolder;
}

td {
  border: none;
  vertical-align: top;
  width: 30%;
}
.gap {
  width: 3.33%;
  background-color: #fff;
}

#hiddenContact {
  display: none;
  border-spacing: 10px;
}
@media screen and (max-width: 860px) {
  #hiddenContact {
    display: block;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  #shownContact {
    display: none;
  }
}
</style>
