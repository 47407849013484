<template>
  <div id="equityInvest">
    <h1>{{ $t("equityInvest.heading") }}</h1>

    <div class="container_equityInvest">
      <div class="container_mid">
        <h1 class="brand">
          <span>{{ $t("equityInvest.neuro") }}</span
          >{{ $t("equityInvest.leap") }}
        </h1>
        <div class="wrapper">
          <div class="equityInvestForm">
            <form
              id="equityInvest"
              ref="formInvest"
              @submit.prevent="submitFormEquity"
            >
              <b-container>
                <b-row>
                  <!-- firstname -->
                  <b-col sm>
                    <label>{{ $t("equityInvest.firstname") }} *</label>
                    <input
                      id="txt_firstname"
                      type="text"
                      name="txt_firstname"
                      required
                    />
                  </b-col>
                  <!-- lastname -->
                  <b-col sm>
                    <label>{{ $t("equityInvest.lastname") }} *</label>
                    <input
                      id="txt_lastname"
                      type="text"
                      name="txt_lastname"
                      required
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <!-- city -->
                  <b-col sm>
                    <label>{{ $t("equityInvest.city") }} *</label>
                    <input id="txt_city" type="text" name="txt_city" required />
                  </b-col>
                  <!-- state -->
                  <b-col sm>
                    <label>{{ $t("equityInvest.state") }} *</label>
                    <input
                      id="txt_state"
                      type="text"
                      name="txt_state"
                      required
                    />
                  </b-col>
                  <!-- country -->
                  <b-col sm>
                    <label>{{ $t("equityInvest.country") }} *</label>
                    <template>
                      <b-form-select
                        id="drpdwn_country"
                        name="drpdwn_country"
                        v-model="selected_investor"
                        :options="getCountries()"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled>{{
                            $t("countries.250")
                          }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </template>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- address -->
                  <b-col sm>
                    <label>{{ $t("equityInvest.address") }} *</label>
                    <input
                      id="txt_address"
                      type="text"
                      name="txt_address"
                      required
                    />
                  </b-col>
                  <!-- zipcode -->
                  <b-col sm>
                    <label>{{ $t("equityInvest.zipCode") }} *</label>
                    <input
                      id="txt_zipcode"
                      type="text"
                      name="txt_zipcode"
                      required
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <!-- phone number -->
                  <b-col sm>
                    <!--10 digits -->
                    <label>{{ $t("equityInvest.phone") }} *</label>
                    <input
                      id="nr_phone"
                      type="number"
                      name="nr_phone"
                      required
                    />
                  </b-col>
                  <!-- email -->
                  <b-col sm>
                    <label>{{ $t("equityInvest.email") }} *</label>
                    <input
                      id="email_address"
                      type="email"
                      name="email_address"
                      required
                      @keyup="input_check_email"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <!-- comment -->
                  <b-col sm>
                    <label>{{ $t("equityInvest.comments") }}</label>
                    <textarea
                      id="messageEquityInvest"
                      name="messageEquityInvest"
                      rows="5"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <!-- submit button -->
                  <b-col>
                    <button
                      type="submit"
                      class="btn_equityInvest"
                      name="submit"
                      value="Submit Now"
                    >
                      {{ $t("equityInvest.btn_submit") }}
                    </button>
                  </b-col>
                  <b-col>
                    <button type="reset" class="btn_contactform">
                      {{ $t("contactform.btn_reset") }}
                    </button>
                  </b-col>
                </b-row>
              </b-container>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="modal-1" v-model="showInvestModal">
        <h2>Thank you for your submission!</h2>
        <br />
        <p>We will get back to you soon.</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import dataService from "../services/dataService";

export default {
  name: "EquityInvest",
  data() {
    return {
      showInvestModal: false,
      selected_investor: null,
    };
  },
  methods: {
    // check if email is valid
    input_check_email: function () {
      if (
        /([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,})$/.test(
          document.getElementById("email_address").value
        ) == true
      ) {
        document.getElementById("email_address").setCustomValidity("");
      } else {
        document
          .getElementById("email_address")
          .setCustomValidity(this.$i18n.t("equityInvest.email_check"));
        document.getElementById("email_address").reportValidity();
      }
    },
    submitFormEquity: function () {
      var firstName = document.getElementById("txt_firstname").value;
      var lastName = document.getElementById("txt_lastname").value;
      var mail = document.getElementById("email_address").value;
      var cityText = document.getElementById("txt_city").value;
      var addressText = document.getElementById("txt_address").value;
      var stateText = document.getElementById("txt_state").value;
      var zipcode = document.getElementById("txt_zipcode").value;
      var commentText = document.getElementById("messageEquityInvest").value;
      var inputCountry = document.getElementById("drpdwn_country");
      var countryText = inputCountry.options[inputCountry.selectedIndex].text;
      var phoneNr = document.getElementById("nr_phone").value;

      var user = {
        mail: mail,
        first_name: firstName,
        last_name: lastName,
        city: cityText,
        country: countryText,
        state: stateText,
        address: addressText,
        phone_nr: phoneNr,
        zipcode: zipcode,
      };

      // send email
      // this.$ref.formInvest
      this.showInvestModal = true;
      //Next Line is to call the conenection to API
      dataService.createEquity(user, commentText);

      alert("Submit successful!");
    },

    //get the countries from English.json for country dropdown
    getCountries: function () {
      var countries = [];
      for (let index = 0; index <= 249; index++) {
        countries[index] = this.$i18n.t("countries." + index);
      }
      return countries;
    },
  },
};
</script>

<style scoped>
/* CSS for contact form */

.col-sm {
  margin: 0.5em;
}

.container_equityInvest {
  width: 100%;
  background-color: #f4892f;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
}

.container_mid {
  width: 100%;
}

h2 {
  margin: 0;
}

a {
  color: black;
}

.mail_crypt {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* VolunteerForm */

* {
  box-sizing: border-box;
}

body {
  background: #f9c75c;
  color: #f4892f;
  line-height: 1.6;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #fff;
}

.wrapper {
  box-shadow: 0 0 20px 0 rgba(243, 142, 19, 0.7);
}

.wrapper > * {
  padding: 1em;
}

.equityInvestForm {
  background: #f9c75c;
}

/* FORM STYLES */

.container_mid form label {
  display: block;
}

.container_mid form p {
  margin: 0;
}

.container_mid form button,
.container_mid form input,
.container_mid form textarea,
.container_mid form select,
.container_mid form option {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #faa535;
}

.container_mid form button {
  background: #f4892f;
  float: left;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
}

.container_mid form button:hover,
.container_mid form button:focus {
  background: #b33d06;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
  cursor: pointer;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
  .wrapper {
    display: grid;
  }

  .wrapper > * {
    padding: 2em;
  }

  .brand {
    text-align: left;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0;
  }
}
</style>
