import emailjs from "@emailjs/browser";

class EMAILJS{
    sendEmail(from, contact) {
        const serviceID='service_sy95fx5'
        var templateID = ''
        const publicKEY = 'Av_F3cs542EOdUEPq'
        if (from == 'contact'){
            templateID = 'template_kc8sba6'
        }
        else if (from == 'newsletter'){
            // need to update when newsletter on
            templateID = 'template_kc8sba6'
        }
        else if (from == 'volunteer'){
          // need to update when newsletter on
          templateID = 'template_kc8sba6'
        }
        else if (from == 'newsletter'){
          // need to update when newsletter on
          templateID = 'template_kc8sba6'
        }
        else if (from =='fieldtrial'){
            templateID='template_yumupz7'
        }
        emailjs
          .sendForm(
            serviceID,
            templateID,
            contact,
            publicKEY
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.text);
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
      }
}

export default new EMAILJS

