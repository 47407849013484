import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from "vue";
import './plugins/bootstrap-vue'
import { BootstrapVue } from "bootstrap-vue/dist/bootstrap-vue.esm";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import App from "./App.vue";
import ElementUI, { Notification, Loading } from "element-ui";
import Vuex from "vuex";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "./i18n";
import VueRouter from "vue-router";
import Routes from "./routes";
import mDatePicker from 'vue-multi-date-picker'


// ...
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(BootstrapVue);Vue.use(mDatePicker)


Vue.prototype.$notify = Notification;
Vue.use(Loading.directive);
Vue.use(VueRouter);
Vue.use(Vuex);

Vue.prototype.$loading = Loading.service;

const store = new Vuex.Store({
  
    state: {
      user: null,
      email: null,
      eventsSignup:"",
      userLevel: "",
      loading: false,
      loadingForgotPassword: false,
      loadingResetPassword: false,
      eventID: null,
    },
    mutations: {
      setAuthUser(state, user) {
        state.user = user;
      },
  
      setCurrentEmail(state, email) {
        state.email = email;
      },

      setUserEventSignup(state,events){
        state.eventsSignup=events;
      },
  
      setUserLevel(state, userLevel) {
        state.userLevel = userLevel;
      },

      setEventID(state,eventID){
        state.eventID=eventID
      },
  
      setLoading(state, loading) {
        state.loading = loading;
      },
  
      setLoadingForgotPassword(state, loadingForgotPassword) {
        state.loadingForgotPassword = loadingForgotPassword;
      },
      setLoadingResetPassword(state, loadingResetPassword) {
        state.loadingResetPassword = loadingResetPassword;
      },
  
      
      toggleSidebarDesktop(state) {
        const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
        state.sidebarShow = sidebarOpened ? false : "responsive";
      },
      toggleSidebarMobile(state) {
        const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
        state.sidebarShow = sidebarClosed ? true : "responsive";
      },
      set(state, [variable, value]) {
        state[variable] = value;
      },
    },
    
    getters: {
  
      getUserLevel(state) {
        return state.userLevel;
      },
  
      getCurrentUser(state) {
        return state.user;
      },

      getUserEventSignup(state){
        return state.eventsSignup;
      },
  
      getEventID(state){
        return state.eventID
      },
      
      isLoggedIn(state) {
        return state.user !== null;
      },
  
      getCurrentEmail(state) {
        return state.email;
      },
  
      getLoadStatus(state) {
        return state.loading;
      },
  
      getLoadStatusForgotPassword(state) {
        return state.loadingForgotPassword;
      },
  
      getLoadStatusResetPassword(state) {
        return state.loadingResetPassword;
      },
    },
  });

const router = new VueRouter({
  routes: Routes,
  mode: "history",
});

new Vue({
  render: (h) => h(App),
  router: router,
  store: store,
  i18n
}).$mount("#app");